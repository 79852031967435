.border-light {
  border-radius: 1em !important;
}

.card {
  max-width: 25rem !important;
}

.card-text {
  text-align: center;
  font-weight: bold;
}

.country-padding {
  padding-top: 1em;
}
.countries-padding-top {
  padding-top: 3em;
}

.flag {
  width: 50%;
}

.numbers {
  font-size: 2vh !important;
}

.nav-fixed-top {
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 1;
}

.pages-padding-top {
  padding-top: 5em;
}

.ph-cases-padding-top {
  padding-top: 1em;
}

.ph-title-center {
  text-align: center !important;
  padding-top: 1.5em;
}

.ph-padding-top {
  padding-top: 1em;
}

.summary-padding {
  padding-top: 4.5em;
}

.sum-margin {
  margin: 25px;
}

#send-to-back {
  display: contents;
}

#about-padding-top {
  padding-top: 4.5em;
}

#chart {
  max-width: 430px;
  margin: 35px auto;
  opacity: 0.9;
  padding-top: 2em;
}
